import styled from "@emotion/styled";
import { Grid} from "@mui/material";

const PageWrapper = styled.div`
  background-color: #010117;
  position: relative;
  overflow: hidden;
  /* overflow: hidden !important; */
`;
const DataImage = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0px")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 899px) {
    max-width: ${(props) => (props.mw899 ? props.mw899 : "")};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "100%")};
  left: ${(props) => (props.l ? props.l : "-6%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(-6%,-100%)")};
  max-width: ${(props) => (props.mw ? props.mw : "20%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "30%")};
  }
`;

const CustomGrid = styled(Grid)`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.fd ? props.fd : "row")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  gap: ${(props) => (props.g ? props.g : "0")};
  @media (max-width: 599px) {
    flex-direction: ${(props) => props.fd599};
  }
`;

export {
  PageWrapper,
  DataImage,
  AbsoluiteImage,
  CustomGrid,
};
