import React from "react";
import {
  AbsoluiteImage,
  CustomGrid,
  DataImage,
  PageWrapper,
} from "../../Styles/style";
import { Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import right from "../../../assets/right.png";
import left from "../../../assets/left.png";
import b1 from "../../../assets/b1.png";
import b2 from "../../../assets/b2.png";
import b3 from "../../../assets/b3.png";
import b4 from "../../../assets/b4.png";
import b5 from "../../../assets/b5.png";
import b6 from "../../../assets/b6.png";
import b7 from "../../../assets/b7.png";
import b8 from "../../../assets/b8.png";
import Paragraph from "../../Components/Paragraph";
import Marquee from "react-fast-marquee";

const ContainerWraper = styled(Container)`
  width: 100%;
  min-height: 100vh;
  background: #fff;
  position: relative;
  display: grid;
  place-items: center;
`;
const MainPage = (props) => {
  return (
    <PageWrapper>
      <ContainerWraper maxWidth="xxl">
        <AbsoluiteImage src={right} />
        <AbsoluiteImage t="0%" l="114%" tran="translate(-114%,0%)" src={left} />
        <Container>
          <CustomGrid container>
            <CustomGrid item xs={12}>
              <Paragraph fs="80px" fs599="50px">
                {"<Cure>"}
              </Paragraph>
            </CustomGrid>
            <CustomGrid item xs={12}>
              <Marquee>
                <DataImage mw="80%" mw599="60%" src={b1} />
                <DataImage mw="80%" mw599="60%" src={b2} />
                <DataImage mw="80%" mw599="60%" src={b3} />
                <DataImage mw="80%" mw599="60%" src={b4} />
                <DataImage mw="80%" mw599="60%" src={b5} />
                <DataImage mw="80%" mw599="60%" src={b6} />
                <DataImage mw="80%" mw599="60%" src={b7} />
                <DataImage mw="80%" mw599="60%" src={b8} />
              </Marquee>
            </CustomGrid>
            <CustomGrid item xs={12}>
              <Paragraph fs="80px" fs599="50px">
                {"</Cancer>"}
              </Paragraph>
            </CustomGrid>
            <CustomGrid item xs={12}>
              <Paragraph>90% into Liquidity and 10% for donation</Paragraph>
            </CustomGrid>
            <CustomGrid item xs={12}>
              <Paragraph>Contract Address: TBA</Paragraph>
            </CustomGrid>
            <CustomGrid item xs={12} g="10px">
              <Link sx={{ textDecoration: "none" }} href="https://twitter.com/bcethtoken" target="blank">
                <Paragraph sx={{ textDecoration: "underline" }}>/X</Paragraph>
              </Link>
              <Link sx={{ textDecoration: "none" }} href="https://t.me/bcethtoken" target="blank">
                <Paragraph sx={{ textDecoration: "underline" }}>
                  /Telegram
                </Paragraph>
              </Link>
            </CustomGrid>
          </CustomGrid>
        </Container>
      </ContainerWraper>
    </PageWrapper>
  );
};

export default MainPage;
